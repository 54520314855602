import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '../../../shared/component/Button';
import { BackButton, LayoutEditIcon } from '../../../shared/component/svgIcon';

function ContentLayoutHeader(props) {
  const { t } = useTranslation();

  const {
    buttonLoading,
    handlePreviousClick,
    handleSubmitFunction,
    selectedLayoutName,
    setSelectedLayoutName,
    disabled,
  } = props;

  const inputRef = useRef(null);

  const [editContentLayoutName, setEditContentLayoutName] = useState(false);

  // Function to handle name edit function
  const handleEditContentLayoutName = (event) => {
    setSelectedLayoutName(event?.target?.value);
  };

  // Function to check enter key clicked inside input field
  const handleKeyDown = (event) => {
    if (event?.keyCode === 13 && event?.key === 'Enter') {
      setEditContentLayoutName(false);
    }
  };

  const handleViewInputField = () => {
    setEditContentLayoutName(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 500);
  };

  return (
    <div className="content-layout-header-wrap">
      <div className="content-layout-header-left">
        <span
          role="presentation"
          onClick={handlePreviousClick}
        >
          <BackButton />
        </span>
        <div className="layout-name-editor">
          {
            editContentLayoutName
              ? (
                <input
                  ref={inputRef}
                  type="text"
                  value={selectedLayoutName}
                  onChange={(event) => handleEditContentLayoutName(event)}
                  onBlur={() => setEditContentLayoutName(false)}
                  onKeyDown={(event) => handleKeyDown(event)}
                />
              )
              : <h1>{selectedLayoutName}</h1>
          }
          {!editContentLayoutName
            && (
              <span
                role="presentation"
                onClick={handleViewInputField}
              >
                <LayoutEditIcon
                  height="15"
                  width="15"
                  color="#212B36"
                />
              </span>
            )}
        </div>
      </div>
      <Button
        label={buttonLoading ? t('saving') : t('save')}
        click={handleSubmitFunction}
        classes={buttonLoading ? 'success-button loading-btn' : 'success-button'}
        disabled={disabled || buttonLoading}
        loading={buttonLoading}
      />
    </div>
  );
}

ContentLayoutHeader.propTypes = {
  buttonLoading: PropTypes.bool.isRequired,
  handlePreviousClick: PropTypes.func.isRequired,
  handleSubmitFunction: PropTypes.func.isRequired,
  selectedLayoutName: PropTypes.string.isRequired,
  setSelectedLayoutName: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ContentLayoutHeader;
