import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import cookies from 'js-cookie';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import loginImage from '../../assets/images/login/loginPage.jpg';
import loginImage1 from '../../assets/images/login/loginPage1.png';
import loginImage2 from '../../assets/images/login/loginPage2.png';
import userIcon from '../../assets/icons/user1.png';
import passwordIcon from '../../assets/icons/password1.png';

import { Input1 } from '../../shared/component/form/Input';
import Button from '../../shared/component/Button';

import auth from '../../utils/api/auth';
import feature from '../../utils/api/feature';
import {
  getLocalStorageItem,
  setLocalStorageItem,
  useRecaptcha,
  setCookieStorageItem,
  getCookieStorageItem,
} from '../../utils/helpers';
import { WorldIcon } from '../../shared/component/svgIcon';
import DropDownMenu from '../../shared/component/DropDownMenu';
import { emailValidation } from '../../shared/component/form/Validation';

function Login(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { VerifyRecaptcha } = useRecaptcha();
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const data = getCookieStorageItem('secure-auth-access');
  const features = getLocalStorageItem('features');
  const role = getLocalStorageItem('role');

  const [loginButtonLoading, setLoginButtonLoading] = useState(false);
  const [loginButtonClicked, setLoginButtonClicked] = useState(false);
  const [logInButtonText, setLogInButtonText] = useState('login');
  const [logInCred, setLogInCred] = useState(null);
  const handleLanguage = (code) => {
    i18next.changeLanguage(code);
    window.location.reload();
  };

  const menuList = [
    {
      code: 'en',
      name: 'English',
      function: handleLanguage,
      disabled: currentLanguageCode === 'en',
    },
    {
      code: 'ar',
      name: 'عربي (Arabic)',
      function: handleLanguage,
      disabled: currentLanguageCode === 'ar',
    },
  ];

  const loginBanners = [
    {
      image: loginImage,
      objectFit: 'cover',
    },
    {
      image: loginImage1,
      objectFit: 'contain',
    },
    {
      image: loginImage2,
      objectFit: 'contain',
    },
  ];

  const settings = {
    dots: false,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: 'linear',
    prevArrow: null,
    nextArrow: null,
    autoplay: true,
  };

  const [value, setValue] = useState({});
  const [disable, setDisable] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  // Function to handle email and password changes
  const changeHandle = (name, changeValue) => {
    setValue({ ...value, [name]: changeValue });
  };

  // Function to handle login request
  const submit = async () => {
    setErrorMsg('');
    setLoginButtonClicked(true);
    if (emailValidation(value?.email)) {
      setLoginButtonLoading(true);
      try {
        // Execute reCAPTCHA and get the token
        const recaptchaToken = await VerifyRecaptcha('login');
        const payload = { ...value, recaptchaToken };
        if (logInButtonText === 'sendOtp') {
          const sendOtpData = {
            email: value?.email,
            recaptchaToken,
          };
          const res = await auth.sendLogInOtp(sendOtpData);
          if (res) {
            setDisable(true);
            setLoginButtonLoading(false);
            setLogInButtonText('submit');
          }
        }
        if (logInButtonText === 'submit') {
          const otpData = {
            email: value?.email,
            logInOtp: value?.logInOtp,
            recaptchaToken,
          };
          await auth.verifyEmail(otpData)
            .then(async () => {
              setDisable(true);
              const token = logInCred?.tokens?.access?.token;
              const decoded = jwtDecode(token);
              const userData = { user: logInCred?.user };
              setCookieStorageItem('secure-auth-access', logInCred?.tokens?.access?.token);
              setCookieStorageItem('secure-auth-refresh', logInCred?.tokens?.refresh?.token);
              setLocalStorageItem('role', decoded?.role);
              setLocalStorageItem('data', userData);
              // If the user is within the register company,
              // then navigate to dashboard or navigate to company register form
              if (logInCred?.user?.company) {
                // If user is authenticated, fetch the features for that user
                const res = await feature.getFeatures();
                setLocalStorageItem('features', res?.data);
                navigate('/admin/dashboard');
                // Run a walkthrough based on new user login
                props?.setRunWalkthrough(!logInCred?.user?.walkthrough || false);
              } else {
                navigate('../register', { state: { formStage: 3, page: 'login' } });
              }
              setLoginButtonLoading(false);
            });
        }
        if (logInButtonText === 'login') {
          localStorage.removeItem('role');
          localStorage.removeItem('data');
          localStorage.removeItem('features');

          const response = await auth.login(payload);

          if (response) {
            const newLogInCred = response.data;
            setLogInCred(newLogInCred);
            setLoginButtonLoading(false);

            if (newLogInCred.user.company.two_factor) {
              setLogInButtonText('sendOtp');
            } else {
              setDisable(true);
              const token = newLogInCred?.tokens?.access?.token;
              const decoded = jwtDecode(token);
              const userData = { user: newLogInCred.user };

              setCookieStorageItem('secure-auth-access', newLogInCred.tokens.access.token);
              setCookieStorageItem('secure-auth-refresh', newLogInCred.tokens.refresh.token);
              setLocalStorageItem('role', decoded.role);
              setLocalStorageItem('data', userData);

              if (newLogInCred.user.company) {
                const res = await feature.getFeatures();
                setLocalStorageItem('features', res.data);
                navigate('/admin/dashboard');
                props?.setRunWalkthrough(!newLogInCred.user.walkthrough || false);
              } else {
                navigate('../register', { state: { formStage: 3, page: 'login' } });
              }
            }
          }
        }
      } catch (error) {
        setDisable(false);
        setLoginButtonLoading(false);
        if (error?.response?.data?.message) {
          setErrorMsg(error?.response?.data?.message);
        } else {
          setErrorMsg('An unexpected error occurred.');
        }
      }
    }
  };

  // To enable and disable button state
  useEffect(() => {
    setErrorMsg('');
    if (logInButtonText === 'sendOtp' && value.email) {
      setDisable(false);
    } else if (logInButtonText === 'submit' && value.email && value.logInOtp && value.logInOtp.length === 6) {
      setDisable(false);
    } else if (logInButtonText === 'login' && value.email && value.password) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  const handleResend = async () => {
    setLoginButtonClicked(true);
    setErrorMsg('');
    setLogInButtonText('sendOtp');
    if (emailValidation(value?.email)) {
      setLoginButtonLoading(true);
      try {
        // Execute reCAPTCHA and get the token
        const recaptchaToken = await VerifyRecaptcha('login');
        const payload = { email: value?.email, recaptchaToken };
        await auth.sendLogInOtp(payload);
        setDisable(true);
        setLoginButtonLoading(false);
        setLogInButtonText('submit');
      } catch (error) {
        setDisable(false);
        setLoginButtonLoading(false);
        if (error?.response?.data?.message) {
          setErrorMsg(error?.response?.data?.message);
        } else {
          setErrorMsg('An unexpected error occurred.');
        }
      }
    }
  };
  useEffect(() => {
    if (typeof token === 'string') {
      // Convert the access token expires date string to a Date object
      const decodeExpiryDate = jwtDecode(data);
      const expiryDate = new Date((decodeExpiryDate.exp) * (1000));

      // Get the current date and time
      const currentDate = new Date();

      // Check if the access token is expired
      if (expiryDate && expiryDate <= currentDate) {
        localStorage.removeItem('data');
        localStorage.removeItem('role');
        localStorage.removeItem('features');
        localStorage.removeItem('walkthroughCompleted');
      } else if ((expiryDate && expiryDate > currentDate)
        && features
        && role) {
        navigate('/admin/dashboard');
      }
    }
  }, []);
  return (
    <div className="login">
      <div className="page">
        <div className="banner-wrap">
          <Slider {...settings}>
            {loginBanners.map((banner) => (
              <div className="banner-container">
                <img
                  src={banner.image}
                  className="login-image"
                  alt="banner"
                  style={{ objectFit: banner.objectFit }}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="form-session">
          <div className="form login-forms">
            <h1 className="loginTitle">{t('welcomeBack')}</h1>
            {logInButtonText === 'sendOtp' && <h4 className="loginTitle1">{t('enterYourEmailForOtp')}</h4>}
            {logInButtonText === 'submit' && <h4 className="loginTitle1">{t('enterThe-6Digit')}</h4>}
            {logInButtonText === 'login' && <h4 className="loginTitle1">{t('enterCredentials')}</h4>}
            <p className="text-danger">{errorMsg}</p>
            <Input1
              icon={userIcon}
              label={t('username')}
              type="text"
              required={false}
              name="email"
              change={changeHandle}
              error={!emailValidation(value?.email) && loginButtonClicked}
              errorMessage={t('invalidEmail')}
              readonly={logInButtonText === 'sendOtp'}
            />
            {logInButtonText === 'login'
              && (
                <Input1
                  icon={passwordIcon}
                  label={t('password')}
                  type="password"
                  required={false}
                  name="password"
                  change={changeHandle}
                  ar={currentLanguageCode}
                />
              )}
            {logInButtonText === 'submit'
              && (
                <Input1
                  icon={passwordIcon}
                  label={t('code')}
                  type="text"
                  required={false}
                  name="logInOtp"
                  change={changeHandle}
                  ar={currentLanguageCode}
                />
              )}
            {logInButtonText === 'login'
              && <div className="forget"><Link to="/forgot-password">{t('forgotPassword?')}</Link></div>}
            <Button
              label={t(logInButtonText)}
              click={submit}
              classes={loginButtonLoading ? 'success-button loading-btn' : 'success-button'}
              loading={loginButtonLoading}
              disabled={disable}
            />
            {logInButtonText !== 'sendOtp'
              && (
                <div className="have-an-account">
                  <p>{logInButtonText !== 'submit' ? t('dontHaveAnAccount?') : t('dontSeeACode?')}</p>
                  {logInButtonText !== 'submit'
                    ? (
                      <Link to="../register">
                        {t('register')}
                      </Link>
                    )
                    : (
                      <p
                        className={!loginButtonLoading ? 'resend-email' : 'resend-email-disabled'}
                        onClick={handleResend}
                        role="none"
                      >
                        {t('resendToEmail')}
                      </p>
                    )}
                </div>
              )}
          </div>
        </div>
      </div>
      <div className={currentLanguageCode === 'ar'
        ? 'language-wrap-absolute-ar'
        : 'language-wrap-absolute'}
      >
        <p>{currentLanguageCode === 'en' ? 'English' : 'عربي '}</p>
        <DropDownMenu
          dropdown="dropdown"
          icon={<WorldIcon />}
          action={menuList}
          tooltip="Language"
        />
      </div>
    </div>
  );
}

Login.propTypes = {
  setRunWalkthrough: PropTypes.node.isRequired,
};

export default Login;
